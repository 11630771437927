import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { desktop, laptop, tabletL, tabletS, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Section from '@/components/common/Section'
import ParticleBackground from '@/components/common/ParticleBackground'
import Step from './ProcessStep'

const WebProcess = props => (
  <Root>
    <Head>
      <Section
        subtitle="PROCESS"
        title={(
          <Fragment>WEBサイト制作の流れ</Fragment>
        )}
        body={(
          <Fragment>フロントビジョンのWEBサイト制作は、ヒアリング／プランニング／設計／構築／運用の5つのフェーズに分かれています。部分的にご依頼いただくことも可能です。小額なご予算（20〜30万円）での制作も承っておりますので、まずは気軽にご相談ください。</Fragment>
        )}
        styles={styles.Section}
      />
    </Head>
    <Main>
      <Background scale={600} />
      <Steps>
        <Step
          title="ヒアリング"
          icon="hearing"
          type="two">
          WEBサイトが担うべき役割や目標を明確にするため、事業／マーケティング／ブランディング／ターゲット／競合／組織体制など、ビジネスや事業全体に関するヒアリングを行います。
        </Step>
        <Step
          title="提案・概算見積"
          icon="estimate"
          type="one">
          具体的なアイデアを発案し、概算見積を提出します。ここまでのサービスは無料で提供します。
        </Step>
        <Step
          title="契約"
          icon="contract"
          type="one">
          概算のお見積り内容に合意頂けましたらご契約頂きます。
        </Step>
        <Step
          title="プランニング"
          icon="planning"
          tags={['ビジネスロードマップ', 'ペルソナ設計', 'カスタマージャーニー', 'キーワードリスト', '競合調査', 'システム要件定義', 'ROI算出']}
          type="two">
          ビジネス全体や商材の把握、各種調査や機能要件の定義を行い、サイトの方向性を固めていきます。
        </Step>
        <Step
          title="見積"
          icon="estimate"
          type="one">
          具体的な作業を盛り込んだ詳細な見積書を提出します。予算に合わせて作成するため、概算見積の金額と大きく乖離することはありません。合意いただけましたら次のフェーズに進みます。
        </Step>
        <Step
          title="設計"
          icon="scale"
          tags={['導線設計', 'サイトマップ', 'ページ設計', 'システム設計']}
          type="two">
          サイト内導線設計、サイトマップやページ構成およびコンテンツ（原稿）の作成、管理画面の機能設計など、プランニングで決まった内容に従って具体的にサイトの設計を行います。
        </Step>
        <Step
          title="構築"
          icon="development"
          tags={['デザイン作成', 'コーディング', '連携機能開発', '業務アプリ開発', 'サイト／システムテスト']}
          type="two">
          戦略や設計の方針にもとづいたデザインの制作と開発を行います。必要に応じてMA/SFA/CRMとの連携や、入力やデータ作成を補助するような簡易的な業務アプリの開発も対応できます。
        </Step>
        <Step
          title="運用"
          icon="support"
          tags={['SEO', 'WEB広告', 'SNS運用', 'プレスリリース', 'メルマガ', '運用レポート', '人員育成', 'コンテンツ制作']}
          type="two">
          公開後の調査／改善企画／効果検証／定期・不定期の更新作業／広告運用／CMS導入時の運用マニュアルの作成・レクチャー／サイト運用人員の育成／コンテンツ制作などを支援します。
        </Step>
      </Steps>
    </Main>
  </Root>
)

export default ThemeConsumer(WebProcess)

const Root = styled.div``

const Background = styled(ParticleBackground)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const Head = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    display: flex;
    align-items: center;
  }
`

const Main = styled.div`
  margin-top: 50px;
  position: relative;
  padding: 64px 96px;
  @media ${mq.and(tabletL)} {
    padding: 48px 32px;
  }
  @media ${mq.and(tabletS)} {
    padding: 32px 24px;
  }
  @media ${mq.and(mobile)} {
    padding: 32px 16px;
  }
`

const Steps = styled.ol`
  position: relative;
`

const styles = {
  Section: {
    root: css`
      width: 100%;
    `,
    main: css`
      margin: 0 auto;
      max-width: 768px;
      @media ${mq.and(desktop, laptop, tabletL)} {
        text-align: center;
        width: 100%;
      }
    `,
    subtitle: css`
      @media ${mq.and(desktop, laptop, tabletL)} {
        text-align: center;
      }
    `,
    title: css`
      @media ${mq.and(desktop, laptop, tabletL)} {
      }
    `
  }
}
