import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Typography from '@/components/common/Typography'

const SubSection = props => {
  const { title, body, titleProps, bodyProps, styles = {}, children, ...others } = props
  return (
    <Root {...others} css={styles.root ? styles.root : undefined}>
      <Main css={styles.main ? styles.main : undefined}>
        {title && (
          <Title
            variant="h3"
            component="h4"
            {...titleProps}
            css={styles.title ? styles.title : undefined}>
            {title}
          </Title>
        )}
        {body && (
          <Body
            variant="body1"
            {...bodyProps}
            css={styles.body ? styles.body : undefined}>
            {body}
          </Body>
        )}
        {children && (
          <Children hasTitle={Boolean(title)}>{children}</Children>
        )}
      </Main>
    </Root>
  )
}

export default ThemeConsumer(SubSection)

const Root = styled.div`
`

const Main = styled.div`
`

const Title = styled(Typography)`
`

const Body = styled(Typography)`
  margin-top: 20px;
`

const Children = styled.div`
  margin-top: ${props => props.hasTitle
    ? '42px'
    : '16px'
  };
  @media ${mq.and(mobile)} {
    margin-top: 24px;
  }
`
